import React, { useState, useEffect } from "react";
import Login from "../GoogleLogin/Login";
import RouteNav from "../RouteNav/RouteNav";
import Backdrop from "../Backdrop/Backdrop";
import SingleActionModal from "../SingleActionModal/SingleActionModal";
import usePageFocus from "../../utils/usePageFocus";
import { clearAuthetication } from "../../utils/userData";

import "./App.css";

function App() {
  const [userInfo, dispatchUserInfo] = useState(undefined);
  const [isRefreshUser, setIsRefreshUser] = useState(false);
  let isFocusPage = usePageFocus();

  useEffect(() => {
    const validateUserCredentialExpiration = () => {
      const credentialExpiredTime = userInfo.exp * 1000;
      const currentTime = new Date().getTime();
      if (credentialExpiredTime < currentTime) {
        setIsRefreshUser(true);
      }
    };
    if (userInfo) {
      validateUserCredentialExpiration();
    }
    return () => {};
  }, [isFocusPage, userInfo]);

  if (!userInfo) {
    return <Login dispatchUserInfo={dispatchUserInfo} />;
  } else {
    // console.log("Login success")
  }

  return (
    <>
      <Backdrop show={isRefreshUser}>
        <SingleActionModal
          headerImgUrl={'/athena-logo.png'}
          title={"You have been signed out"}
          content={"Your session has timed out due to inactivity, as required by security policy. Please sign in again to continue using LaunchPad web application."}
          actionTitle={"Sign in"}
          actionCallback={() => clearAuthetication(true)}
        />
      </Backdrop>
      <Login dispatchUserInfo={dispatchUserInfo} />
      <RouteNav userInfo={userInfo} />
    </>
  );
}

export default App;
