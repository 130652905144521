import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import CompanyLogo from "../../asset/athena-logo-light.jpeg";
import RippleButton from "../RippleButton/RippleButton";
import { IoStatsChart } from "react-icons/io5";
import {
  MdOutlinePermDataSetting,
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import "./Sidebar.css";

const SidebarHeader = ({ isCollapsed }) => {
  return (
    <div className="sidebar-header px-3 py-3">
      <p
        className="company-header-text h3 pb-0 mb-0"
        style={{
          opacity: isCollapsed ? 0 : 1,
        }}
      >
        Athena Launchpad
      </p>
      <Link
        className="p-3 link-dark text-decoration-none"
        style={{
          display: isCollapsed ? "block" : "none",
        }}
        to="/"
      >
        <img src={CompanyLogo} width={26} height={26} alt="athena-logo" />
      </Link>
    </div>
  );
};

const SidebarBody = ({ isCollapsed, features }) => {
  const [collapseFeatureGroupList, setCollapseFeatureGroupList] = useState([]);
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  const handleCollapseFeatureGroup = (item) => {
    setCollapseFeatureGroupList(
      collapseFeatureGroupList.map((featureGroup) =>
        featureGroup.id === item.id
          ? { ...featureGroup, isOpen: !featureGroup.isOpen }
          : featureGroup
      )
    );
  };

  useEffect(() => {
    setCollapseFeatureGroupList(
      features.map((featureGroup) => ({
        ...featureGroup,
        isOpen: !isCollapsed,
      }))
    );
  }, [features, isCollapsed]);

  return (
    <div className="sidebar-body">
      {collapseFeatureGroupList.map((featureGroup) => {
        return (
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            key={featureGroup.id}
          >
            <div className="px-3 pt-2 w-100">
              <div className="d-flex align-items-center pb-1 w-100">
                <RippleButton
                  className="d-flex align-items-center justify-content-center pb-1 w-100 px-0"
                  onClick={() => {
                    if (isCollapsed) return;
                    handleCollapseFeatureGroup(featureGroup);
                  }}
                >
                  <div className="d-flex align-items-center">
                    {featureGroup.id === "navigator" ? (
                      <IoStatsChart fontSize={20} color={"#132880"} />
                    ) : (
                      <MdOutlinePermDataSetting
                        fontSize={20}
                        color={"#132880"}
                      />
                    )}
                  </div>
                  {isCollapsed || (
                    <>
                      <p className="d-flex list-group-title-text my-0 ms-2">
                        {featureGroup.label}
                      </p>
                      <div className="d-flex align-items-center justify-content-end flex-grow-1">
                        {featureGroup.isOpen ? (
                          <MdOutlineKeyboardArrowUp
                            fontSize={24}
                            color={"#132880"}
                          />
                        ) : (
                          <MdOutlineKeyboardArrowDown
                            fontSize={24}
                            color={"#132880"}
                          />
                        )}
                      </div>
                    </>
                  )}
                </RippleButton>
              </div>
              <ul
                className={
                  featureGroup.isOpen
                    ? "list-unstyled opened-list"
                    : "list-unstyled collapsed-list"
                }
              >
                {featureGroup.features && featureGroup.features.length
                  ? featureGroup.features.map((item) => {
                      const isSelected = splitLocation[1] === item.url_path;
                      return (
                        <li
                          className="list-group-item-wrapper"
                          key={item.id}
                          style={{
                            opacity: isCollapsed ? 0 : 1,
                          }}
                        >
                          <Link
                            className={
                              isSelected
                                ? "list-group-item list-group-item-action active"
                                : "list-group-item list-group-item-action"
                            }
                            to={"/" + item.url_path}
                          >
                            <p className="list-group-item-text mb-0">
                              {item.label}
                            </p>
                          </Link>
                        </li>
                      );
                    })
                  : null}
              </ul>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const SidebarFooter = ({ isCollapsed, userInfo }) => {
  return (
    <div className="sidebar-footer">
      <div
        className="d-flex justify-content-center align-items-center w-100 my-3 pt-3"
        style={{
          borderTop: isCollapsed ? "none" : "1px rgba(0, 0, 0, 0.08) solid",
        }}
      >
        <img
          className="rounded-circle"
          src={userInfo.picture}
          alt="avatar"
          width={28}
          height={28}
        />
        {isCollapsed || (
          <p
            className="fw-bold overflow-hidden ms-2 mb-0"
            style={{
              color: "#e66912",
              whiteSpace: "nowrap",
            }}
          >{`${userInfo.email}`}</p>
        )}
      </div>
    </div>
  );
};

const Sidebar = ({ features, userInfo }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleCollapseSidebar = (isCollapsed) => {
    setIsCollapsed(isCollapsed);
  };

  return (
    <div className={isCollapsed ? "sidebar-wrapper close" : "sidebar-wrapper"}>
      <div
        className={isCollapsed ? "sidebar close" : "sidebar"}
        onMouseEnter={() => handleCollapseSidebar(false)}
        onMouseLeave={() => handleCollapseSidebar(true)}
      >
        <SidebarHeader isCollapsed={isCollapsed} />
        <SidebarBody isCollapsed={isCollapsed} features={features} />
        <SidebarFooter isCollapsed={isCollapsed} userInfo={userInfo} />
      </div>
    </div>
  );
};

export default Sidebar;
