import React, { useState, useEffect } from "react";
import { GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import Backdrop from "../Backdrop/Backdrop";
import CircularProgress from "../CircularProgress/CircularProgress";
import {
  clearAuthetication,
  getSessionCredential,
  fetchUserInfo,
  parseCredential,
  refreshCredential,
  storeSessionCredential,
} from "../../utils/userData";
import "../GoogleLogin/Login.css";

const clientId = process.env.REACT_APP_OAUTH_CLIENT_ID;

export default function Login({ dispatchUserInfo }) {
  const [state, setStateLogin] = useState({
    credential: getSessionCredential(),
    refreshTimeout: undefined,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [userInfo, setStateUserInfo] = useState(undefined);
  useEffect(() => {
    if (!state.credential) {
      if (state.refreshTimeout) {
        clearTimeout(state.refreshTimeout);
        setStateLogin({});
      }
      return;
    }

    if (!state.refreshTimeout) {
      let decoded = jwt_decode(state.credential.id_token);
      let timeoutTime = decoded.exp * 1000 - (new Date().valueOf() - 5 * 60000);
      if (timeoutTime > 0) {
        const newTimeout = setTimeout(async () => {
          const credential = await refreshCredential(state.credential).then(
            (results) => {}
          );
          setStateLogin({ ...state, credential, refreshTimeout: undefined });
        }, timeoutTime);

        setStateLogin({ ...state, refreshTimeout: newTimeout });
      } else {
        clearAuthetication(true);
      }
    } else if (!userInfo) {
      setIsLoading(true);
      const loadUserInfo = async () => {
        const userInfo = await fetchUserInfo(state.credential);
        setStateUserInfo(userInfo);
        dispatchUserInfo(userInfo);
      };

      loadUserInfo();
    }

    // return () => clearTimeout(state.refreshTimeout);
  }, [state, userInfo, dispatchUserInfo]);

  if (state.credential) {
    return (
      <div>
        <span hidden key={userInfo?.email} id="email">
          {userInfo?.email}
        </span>
        <span hidden key={state.credential.id_token} id="id_token">
          {state.credential.id_token}
        </span>
        <span hidden id="impersonated_user"></span>
        <span hidden>
          <GoogleLogin
            clientId={clientId}
            cookiePolicy={"single_host_origin"}
            isSignedIn={true}
          />
        </span>
      </div>
    );
  }

  const onSuccess = (res) => {
    const credential = parseCredential(res.credential);
    storeSessionCredential(credential);
    setStateLogin({ credential });
    fetchUserInfo(credential).then(setStateUserInfo);
  };

  const onFailure = (res) => {
    console.log("Login failed: res:", res);
    clearAuthetication();
  };

  return (
    <div>
      <Backdrop show={isLoading}>
        <CircularProgress />
      </Backdrop>
      <div className="title">ATHENA LAUNCHPAD</div>
      <div className="center">
        <GoogleLogin
          onSuccess={onSuccess}
          onFailure={onFailure}
          useOneTap
          auto_select
        />
        {/* <GoogleLogin
					clientId={clientId}
					buttonText="Sign in with Google"
					onSuccess={onSuccess}
					onFailure={onFailure}
					cookiePolicy={'single_host_origin'}
					style={{ marginTop: '100px' }}
					isSignedIn={true}
					scope={'email profile openid'}
					responseType={'id_token permission'}
				/> */}
      </div>
    </div>
  );
}
