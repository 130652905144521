import { BrowserRouter, Route } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import Retool from "react-retool";
import Logo from "../../asset/athena-logo.jpeg";

import "./RouteNav.css";

export default function RouteNav({ userInfo }) {
  const accessFeatures = userInfo.features;
  let routes = [];

  accessFeatures.forEach((featureGroup) => {
    routes = routes.concat(
      featureGroup.features.reduce(function (result, item) {
        if (item.url_path != null && item.iframe_url != null) {
          result.push(item);
        }
        return result;
      }, [])
    );
  });

  return (
    <div className="wrapper w-100">
      <BrowserRouter>
        <div className="d-flex flex-row w-100">
          <Sidebar userInfo={userInfo} features={accessFeatures} />
          <div className="d-flex flex-column flex-grow-1">
            <div className="retool-app flex-grow-1 h-100 position-relative">
              <Route key={"home-route"} path={"/"} exact>
                <div className="center-container">
                  <div className="row-container">
                    <div className="welcome">WELCOME TO ATHENA LAUNCHPAD</div>
                    <div className="images-container">
                      <img className="images" src={Logo} alt="Logo" />
                    </div>
                  </div>
                </div>
              </Route>
              {routes.map((route) => (
                <Route key={route.url_path} path={`/${route.url_path}`} exact>
                  <div className="retool-box">
                    <Retool url={route.iframe_url}/>
                  </div>
                </Route>
              ))}
            </div>
          </div>
        </div>
      </BrowserRouter>
    </div>
  );
}
