import { useState, useEffect } from "react";

const usePageFocus = () => {
  const [isFocusPage, setIsFocusPage] = useState(true);

  useEffect(() => {
    window.addEventListener("focus", (_) => {
      setIsFocusPage(true);
    });
    window.addEventListener("blur", (_) => {
      setIsFocusPage(false);
    });
    return () => {
      window.removeEventListener("focus", (_) => {});
      window.removeEventListener("blur", (_) => {});
    };
  }, []);

  return isFocusPage;
};

export default usePageFocus;
