import "./SingleActionModal.css";

const SingleActionModal = ({
  headerImgUrl,
  title,
  content,
  actionTitle,
  actionCallback,
} = {}) => {
  return (
    <div
      className="modal fade show d-block"
      id="singleActionModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="singleActionModalLabel"
      aria-hidden="true"
      role={"dialog"}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content px-4">
          <div className="modal-header border-0 pb-1">
            <div className="d-flex flex-column">
              <img
                src={headerImgUrl}
                width={110}
                height={35}
                alt="logo"
              />
              <h6
                className="modal-title fw-bold pt-3 modal-header-text"
                id="singleActionModalLabel"
              >
                {title}
              </h6>
            </div>
          </div>
          <div className="modal-body border-0 py-0 pt-2">
            <p className="fw-normal modal-text">{content}</p>
          </div>
          <div className="modal-footer border-0 pt-2 pb-3">
            <button
              type="button"
              className="btn btn-primary fw-bold px-4 custom-btn modal-text"
              onClick={actionCallback}
            >
              {actionTitle}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleActionModal;